import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { getDocumentsService } from "../../Service/DocumentsService/DocumentsService";
import { getHouseInfoByAssets } from "../../Service/HouseInfoByAssets/HouseInfoByAssets";
import { getLikesService } from "../../Service/LikesService/LikesService";
import FirstPartAppoinmentEndModal from "./FirstPartAppoinmentEndModal";
import SecondPartAppoinmentEndModal from "./SecondPartAppoinmentEndModal";
import ThirdPartAppoinmentEndModal from "./ThirdPartAppoinmentEndModal";

export default function SwitchModal(props: any) {
  const [loading, setLoading] = useState<any>(true);
  const [planosFavoritos, setPlanosFavoritos] = useState<any>([]);
  const [informacionViviendas, setInformacionVivendas] = useState<any>([]);
  const [documentacion, setDocumentacion] = useState<any>([]);
  const [documentationLanguage, setDocumentationLanguage] = useState("es");

  const {
    mainImage,
    setselectedValue,
    setDisplayEmailPhoneClient,
    setOpenModal,
    setSelectedStep,
    selectedStep,
    sendJson,
    setTab,
    promotion,
    client,
    formik,
    seconds,
    minutes,
    hours,
    setTipArchetype,
    setTipRelationalStyle,
  } = props;

  const formikTask = useFormik({
    initialValues: {
      contactoagendado: false,
      emailenviado: false,
      informacionsolicitada: false,
    },
    validationSchema: null,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const fetchData = async () => {
      if (client?.id && promotion?.id) {
        try {
          const likesResponse = await getLikesService(client.id, promotion.id);
          // funcion que a cada uno de los elementos del array planos que viene en la response le añada un nuevo campo "generate_file" con valor true
          likesResponse.data.planos = likesResponse.data.planos.map(
            (plano: any) => {
              return { ...plano, generate_file: true };
            }
          );
          setPlanosFavoritos(likesResponse.data.planos);
        } catch (error) {
          console.log(
            "Hubo un error al obtener las imágenes y los planos marcados como favoritos"
          );
        } finally {
          setLoading(false);
        }
      }
    };

    if (client?.id && promotion?.id) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (promotion?.id && !documentacion) {
      getDocumentsService(promotion?.id).then((response: any) => {
        console.log(response.data);
        setDocumentacion(response.data);
      });
    }
  }, []);

  // Obtenemos la información de las casas a través del ID de los planos.
  useEffect(() => {
    const assetIds = planosFavoritos?.map((plano: any) => plano.asset_id);
    if (assetIds && assetIds.length > 0) {
      getHouseInfoByAssets(assetIds).then((response: any) => {
        setInformacionVivendas(response.data);
      });
    }
  }, [planosFavoritos]);

  if (loading) return <div className="my-4">Cargando...</div>;

  switch (selectedStep) {
    case 0:
      return (
        <FirstPartAppoinmentEndModal
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          formik={formik}
          promotion={promotion}
          client={client}
          sendJson={sendJson}
          planosFavoritos={planosFavoritos}
          loadingPlanos={loading}
          documentacion={documentacion}
          documentationLanguage={documentationLanguage}
        ></FirstPartAppoinmentEndModal>
      );
    case 1:
      return (
        <SecondPartAppoinmentEndModal
          setselectedValue={setselectedValue}
          setDisplayEmailPhoneClient={setDisplayEmailPhoneClient}
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          formikTask={formikTask}
          promotion={promotion}
          client={client}
          sendJson={sendJson}
          planosFavoritos={planosFavoritos}
          loadingPlanos={loading}
          documentacion={documentacion}
          documentationLanguage={documentationLanguage}
        ></SecondPartAppoinmentEndModal>
      );
    case 2:
      return (
        <ThirdPartAppoinmentEndModal
          mainImage={mainImage}
          promotion={promotion}
          client={client}
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          setselectedValue={setselectedValue}
          sendJson={sendJson}
          setTab={setTab}
          formik={formik}
          seconds={seconds}
          minutes={minutes}
          hours={hours}
          formikTask={formikTask}
          planosFavoritos={planosFavoritos}
          setPlanosFavoritos={setPlanosFavoritos}
          loadingPlanos={loading}
          documentacion={documentacion}
          informacionViviendas={informacionViviendas}
          setTipArchetype={setTipArchetype}
          setTipRelationalStyle={setTipRelationalStyle}
          setDocumentationLanguage={setDocumentationLanguage}
          documentationLanguage={documentationLanguage}
        ></ThirdPartAppoinmentEndModal>
      );
    default:
      return <></>;
  }
}
